<template>
  <nav class="navbar fixed-top navbar-expand-lg navbar-dark bg-primary text-left">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"><img src="@/assets/imgs/logo-ecopark-h.png" class="logo" alt=""></a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll"
        aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarScroll">
        <ul class="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll" style="--bs-scroll-height: 200px;">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/dashboard' }" exact active-class="active"><i
                class="fa fa-pie-chart fa-lg"></i> Dashboard</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/reservas-hoy' }" exact active-class="active"><i class="fa fa-calendar fa-lg"></i> Reservas del día</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/reservas' }" exact active-class="active"><i class="fa fa-list fa-lg"></i> Ver Reservas</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ path: '/pagos' }" exact active-class="active"><i class="fa fa-money fa-lg"></i> Pagos</router-link>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-book fa-lg"></i> Reportes
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><router-link class="dropdown-item" :to="{ path: '/reporte-lavadas' }" exact active-class="active"><i class="fa fa-bath fa-lg"></i>
                Lavado de Vehículos</router-link></li>
            </ul>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <i class="fa fa-cogs fa-lg"></i> Administrar
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <li><router-link class="dropdown-item" :to="{ path: '/tarifas' }" exact active-class="active"><i class="fa fa-dollar fa-lg"></i>
                  Tarifas</router-link></li>
              <li><router-link class="dropdown-item" :to="{ path: '/servicios' }" exact active-class="active"><i class="fa fa-tags fa-lg"></i>
                  Servicios</router-link></li>
              <li><router-link class="dropdown-item" :to="{ path: '/ciudades' }" exact active-class="active"><i class="fa fa-globe fa-lg"></i>
                  Ciudades</router-link></li>
              <li><router-link class="dropdown-item" :to="{ path: '/aerolineas' }" exact active-class="active"><i class="fa fa-plane fa-lg"></i>
                  Aerolineas</router-link></li>
            </ul>
          </li>
        </ul>
        <form class="d-flex">
          <button type="button" @click="exit()" class="btn btn-danger"><i class="fa fa-power-off fa-lg"></i> Salir</button>
        </form>
      </div>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'NavbarComponent',
  mounted() {
  },
  methods: {
    exit(){
      document.cookie = 'token' + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.bg-primary {
  background-color: #013cae !important;
}

.logo {
  width: 10rem;
}

.text-left {
  text-align: left;
}
</style>