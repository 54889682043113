import { createRouter, createWebHistory } from 'vue-router'

import Login from '../components/auth/Login.vue';
import Dashboard from '../components/Dashboard.vue';
import BookingToday from '../components/bookings/BookingToday.vue';
import ViewBooking from '../components/bookings/ViewBooking.vue';
import Payments from '../components/bookings/Payments.vue';
// ADMINISTRATION
import Airlines from '../components/administration/Airlines.vue';
import Cities from '../components/administration/Cities.vue';
import Rates from '../components/administration/Rates.vue';
import Services from '../components/administration/Services.vue';
import Washed from '../components/reports/Washed.vue';
import { checkToken } from '../services/middleware.js';
import { checkTokenLogin } from '../services/middlewareLogin.js';

const routes = [
  { path: '', name: 'login', component: Login, beforeEnter: checkTokenLogin },
  { path: '/dashboard', name: 'dashboard', component: Dashboard, beforeEnter: checkToken },
  { path: '/reservas-hoy', name: 'reservas-hoy', component: BookingToday, beforeEnter: checkToken },
  { path: '/reservas', name: 'reservas', component: ViewBooking, beforeEnter: checkToken },
  { path: '/pagos', name: 'pagos', component: Payments, beforeEnter: checkToken },
  { path: '/reporte-lavadas', name: 'reporte-lavadas', component: Washed, beforeEnter: checkToken },
  { path: '/tarifas', name: 'tarifas', component: Rates, beforeEnter: checkToken },
  { path: '/servicios', name: 'servicios', component: Services, beforeEnter: checkToken },
  { path: '/ciudades', name: 'ciudades', component: Cities, beforeEnter: checkToken },
  { path: '/aerolineas', name: 'aerolineas', component: Airlines, beforeEnter: checkToken },

];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
